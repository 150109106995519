.topTitle {
  text-align: left !important;
  margin: 0px 5px 10px 5px !important;
  font-family: "system-ui" !important;
  font-size: 6.5rem !important;
}

.subtitle {
  margin-top: auto !important;
  font-family: "system-ui" !important;
  margin-bottom: 30px !important;
  font-size: 2rem !important;
  text-align: center !important;
  color: #052e16 !important;
}

.stageTitle {
  text-align: left !important;
  margin: 10px 5px 0px 10px !important;
  font-family: "system-ui" !important;
  font-size: 3rem !important;
}

.versionTitle {
  margin: 0px 15px 10px 5px !important;
  font-family: "system-ui" !important;
  font-size: 1.2rem !important;
  text-align: right !important;
}

.cellStyle {
  border-right: 0.5px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
  padding: 20px !important;
  font-family: "system-ui" !important;
  font-size: 1.4rem !important;
}

.transaction {
  text-align: right !important;
  margin: 10px 5px !important;
  color: grey !important;
  font-size: 1.2rem !important;
  font-family: "system-ui" !important;
}

.QRText {
  align-self: center !important;
  font-size: 2rem !important;
  font-family: "system-ui" !important;
  margin-right: 5%;
  text-align: center !important;
  color: #052e16 !important;
}

@media screen and (max-width: 840px) {
  .topTitle {
    font-size: 3rem !important;
  }

  .subtitle {
    font-size: 1.5rem !important;
  }

  .stageTitle {
    font-size: 2rem !important;
  }

  .versionTitle {
    font-size: 0.5rem !important;
  }

  .cellStyle {
    padding: 10px !important;
    font-size: 0.5rem !important;
  }

  .transaction {
    font-size: 0.7rem !important;
  }

  .QRText {
    font-size: 1.5rem !important;
  }
}

@media screen and (max-width: 660px) {
  .transaction {
    font-size: 0.3rem !important;
  }
}
