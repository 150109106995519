.darkMode {
    /* background-image: url("../../public/SectionBackground.png"); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    /* height: 100vh;  */
    justify-content: center;
    background-color: #BBC3A4;
    /* background-color: #627254; */
}

.lightMode {
    justify-content: center;
    background-color: #d7e4c0b5;
    text-align: center;
    /* height: 100vh; */
}