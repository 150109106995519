.btn {
  border-radius: 50px !important;
  font-family: 'system-ui' !important;
  text-transform: none !important;
  background: rgb(118, 136, 91) !important;
  border: solid !important;
  border-width: 1px !important;
  border-color: rgb(118, 136, 71) !important;
  font-size: 27px !important;
  padding: 1.4rem 10rem !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 14px 6px !important;
}

@media screen and (max-width: 800px) {
  .btn {
    padding: 1.4rem 5rem !important;
    font-size: 20px !important;
  }
    
}