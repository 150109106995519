.responsiveTitle {
    color: #282928 !important;
    font-size: 1.5rem !important;
    text-align: center !important;
}

@media screen and (max-width: 500px) {
    .responsiveTitle {
        font-size: 1.1rem !important;
    }  
    
    .checkboxContainer {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }
}

.checkboxContainer {
    display: grid;
    margin: 0;
    gap: 0.1rem 3rem;
    place-items: center;
}