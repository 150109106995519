.activeHeader {
  background-color: rgba(120, 192, 243, 0.453) !important; 
  width: 100%;
  color: black;
  height: 1rem;
  padding: 20px;
}

.inactiveHeader {
  background-color: rgba(49, 53, 55, 0.453) !important; 
  width: 100%;
  color: black;
  height: 1rem;
  padding: 20px;
}

.completedHeader {
  background-color: #D7E4C0 !important; 
  width: 100%;
  color: black;
  height: 1rem;
  padding: 20px;
}