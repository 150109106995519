.container {
  width: 50vw;
  height: 60vh;
  z-index: 0;
}

@media screen and (max-width: 600px) {
  .container {
    width: 75vw;
    height: 60vh;
  }
}

@media screen and (max-width: 1025px) {
  .container {
    padding: 0;
  }
}
