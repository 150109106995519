.informationContainer {
    background-image: url("../../public/SectionBackground4.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    height: 100vh;
  }

  .membersContainer {
    background-image: url("../../public/FullBackground.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem;
  }

  @media (min-width: 540px) {
    .membersContainer {
      padding: 10rem; /* Ajustar el padding para pantallas móviles */
    }
  }