.centeredNav {
  position: absolute; /* Permite el posicionamiento absoluto */
  left: 50%; /* Coloca el elemento en el 50% del ancho de la pantalla */
  transform: translateX(-50%);
  background-color: rgb(215, 228, 192);
}

.addProdBtn {
  border-radius: 50px !important;
  font-family: system-ui !important;
  text-transform: none !important;
  background: rgb(118, 136, 91) !important;
  border: solid !important;
  border-width: 0.5px !important;
  border-color: rgb(118, 136, 71) !important;
  margin-right: 20px !important;
}

.addProdBtn2 {
  border-radius: 50px !important;
  background: rgb(118, 136, 91) !important;
  margin-right: 10px !important;
  color: white !important;
}

.list {
  display: flex;
  gap: 1rem;
  padding: 0;
}

.list a {
  color: rgb(255, 255, 255);
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  transition: border-bottom 0.3s ease;
  font-family: "system-ui";
  font-size: 2.2vw;
  font-weight: 100;
  letter-spacing: 1.5px;
}

.list a:hover,
.list a:active,
.list a.active {
  border-bottom: 4px solid #68a057c4;
}

.headerTitle {
  color: #333333 !important;
  font-family: "system-ui" !important;
  white-space: nowrap !important;
  font-size: 0.8rem !important;
}

@media screen and (min-width: 500px) {
  .headerTitle {
    font-size: 1.5rem !important;
  }
}
