.container {
    /* background-image: url("../../public/FullBackground.png"); */
    /* background-color:" rgb(215, 228, 192)";  */
    /* background-color: #d7e4c0b5; */
    background-color: rgba(245, 250, 242, 0.686);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    height: 100%;
    min-height: 100vh;
    overflow: auto;
}
