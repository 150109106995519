.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.textfield {
    width: 25rem;
    background-color: white;
    border-radius: 16px;
}

.button {
    width: 20rem;
    height: 50px;
}