/* PrincipalButton.css */
.principalButton {
    color: rgb(71 85 105) !important;
    /* background-color: #E1F0DA !important; */
    background-color: transparent !important;
    /* margin: 1rem 0.5rem !important; */
    font-family: system-ui !important;
    font-size: 1.35vw !important;
    padding: 1.5rem 1.2vw !important;
    text-transform: none !important;
    letter-spacing: 0.08vw !important;
    display: inline-block !important;
    white-space: nowrap !important;
    font-weight: 300 !important;
    /* box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1); */
    border-color: black;
    height: fit-content;
    text-align: center;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-right-width: 1px;
    border-left-width: 1px;
}
/* PrincipalButton.css 
.principalButton {
    color: rgb(71 85 105) !important;
    background-color: transparent !important;
    font-family: system-ui !important;
    font-size: 2.5vw; 
    padding: 1vw 2vw !important;
    text-transform: none !important;
    letter-spacing: 1px !important;
    display: inline-block !important;
    white-space: nowrap !important;
    font-weight: 300 !important;
    border-color: black;
    text-align: center;
    border-width: 1px; 
    box-sizing: border-box;
}
*/
/* Media query para ajustar el tamaño del botón en pantallas más pequeñas
@media screen and (max-width: 768px) {
    .principalButton {
        font-size: 4vw; 
        padding: 2vw 4vw !important;
    }
}
*/